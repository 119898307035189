//init
facebookConfig();

function facebookConfig() {
  let timer = false;
  $(window).resize(function() {
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        boxWidth=$('#js-facebook').width();
        currentWidth=$('#js-facebook .fb-page').attr('data-width');
        if(boxWidth != currentWidth){
          $('#js-facebook .fb-page').attr('data-width', boxWidth);
          FB.XFBML.parse(document.getElementById('js-facebook'));
        }
      }, 200);
  });
}